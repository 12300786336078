.rtl .container_spacing {
  margin: 30px 60px 30px 30px !important;
}
.rtl .shareIconRtl {
  width: 40%;
  height: 15px;
  border-radius: 40%;
  background: white;
  position: absolute;
  left: 18px;
  top: 24px;

  margin-right: 20px;
}

.rtl .sharedVoiceNoteRtl {
  padding: 8px 5px 10px 0px;
  position: relative;
  right: 0px;
  left: 10px;
  margin-left: 10px;
}
.rtl .voicenotesSearchbarDivRtl {
  display: flex;
  margin-right: auto;
}
.rtl #waveform > div > div {
  position: absolute;
  z-index: 2;
  right: 0px;
  top: 0px;
  bottom: 0px;
  overflow: hidden;
  height: 50px;
  display: block;

  transition: width 300ms ease-in-out 0s;
  box-sizing: border-box;
  width: 0px;
}

.rtl .addButton {
  margin-left: 0;
  margin-right: 1.5rem;
}

.rtl .staffProfileIcon {
  top: 63px !important;
  right: 80px !important;
}

.rtl .staffPerformanceBtn {
  margin-left: 1.5rem;
  margin-right: 0;
}

.rtl .auth .auth_left .card {
  right: 198px;
}

.rtl .react-tel-input .selected-flag .flag {
  right: 7px;
}

.rtl .react-tel-input .selected-flag .arrow {
  right: 21px;
}

.rtl .new_timeline {
  padding-right: 59px;
}

.rtl .new_timeline .bullet {
  right: 37px;
}

.rtl .new_timeline::before {
  right: 41px;
}

.rtl .rightIconStyle {
  left: 4px;
  right: 0;
}
.rtl .filterContainer {
  margin-left: 20px;
  margin-right: 0px;
}
.rtl .viewEmailTaskStatusStyles {
  margin-right: 0px;
  margin-left: 20px;
}
.rtl .viewEmailActions {
  margin-right: 0px;
  margin-left: 10px;
}
.rtl .viewEmailUserStyles {
  margin-left: 0px;
  margin-right: 15px;
}
.rtl .viewEmailSenderDetailsFrom {
  margin: 25px 15px 8px 0px;
}
.rtl .viewEmailCommonDetails {
  margin: 15px 15px 20px 0px;
}

.rtl .profileCameraIcon {
  left: 0 !important;
  right: unset;
}

.rtl .groupMemberProfileIcon {
  right: unset;
  left: 0;
}

.rtl .selectMemberIcon {
  left: unset;
  right: 36px !important;
}

.rtl .addGroupIcon {
  right: unset;
  left: 20px;
  transform: rotate(180deg);
}

.rtl .countrySelectContainer option,
.rtl .react-datetime-picker__inputGroup {
  direction: ltr !important;
}

.rtl .countrySelectContainer option {
  text-align: right;
}

.rtl .groupProfileCameraIcon {
  right: unset;
  left: 0 !important;
}

.rtl .groupMemberList {
  direction: rtl !important;
}

.rtl .viewContact {
  margin-left: 5px;
}

.rtl .msgSendIcon {
  transform: rotate(180deg);
}
.rtl .commentSectionIcon {
  padding-right: 0px;
}

.rtl .msgHeaderSub {
  margin-right: 16px;
}

.rtl .chatOwnerTime {
  text-align: left;
}

.rtl .chatOtherTime {
  text-align: right !important;
}

.rtl .chatOtherUserTime {
  text-align: left !important;
}

.rtl .chatDocTime {
  margin-left: 8px !important;
}

.rtl .offcanvas-active .container-fluid {
  padding-right: 0px !important;
}

.rtl .performanceReportGraph .apexcharts-legend  {
  right: unset !important;
  left: 32px !important;
}

.rtl .custom-checkbox .custom-control-label::after {
  right: -24px !important;
}

.rtl .formikLocationIcon {
  margin-right: 0;
  margin-left: 8px;
}

.rtl .subtaskPreviewBtn {
  margin-left: 0;
  margin-right: 7px;
}