
.labelStyle {
    font-weight: 500;
    font-size: 14px;
}

.performanceInfoTitle {
    padding-top: 8px;
    width: 100%;
    /* font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    color: #2c2945;
    padding-bottom: 0px !important; */
}

.card-body{
    padding-top: 0px !important;
}

ul.list-group li {
    line-height: 34px;
    float: left;
    width: 100%;
}

ul.list-group li label{
    font-weight: 500;
    width: 42%;
}

.inprogress{
    color: #03a9f4;
    font-weight: 500;
}

.tag{
    font-weight: 600 !important; 
}


