.notificationBar {
    background: #f4bd34de;
    color: black;
    height: 52px;
    position: sticky;
    top: 0;
    z-index: 999999;
    width: 100%;
  }
  
  .notificationBar h6{
    font-size: 14px;
    text-align: center;
  }

.notificationBar svg.iconify--carbon{
    background: #fff;
    margin-left: 10px;
    border-radius: 50%;
    padding: 2px;
}