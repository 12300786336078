.dashboard .card-header {
    background: #0808081f;
}

.dashboard .card-header .card-title{
    margin-left: 2%;
}

.dashboard .card-body .MuiDataGrid-root{
    min-height: 300px !important;
    max-height: auto;
    height: auto !important;
}