.eventListscroll {
  max-height: 75vh;
  overflow-y: auto;
  /* overflow-x: hidden; */
  scroll-behavior: smooth;
}

.eventListscroll::-webkit-scrollbar {
  width: 10px !important;
}
.eventListscroll::-webkit-scrollbar-thumb {
  border-radius: 6px;
  border: 6px solid #c1c2c1;
}
.ellipsisEventCard {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.event_list {
  width: 80%;
  position: relative;
  margin-top: 0.4rem;
}

.customFullCalendar .fc-event {
  display: flex;
}

.customFullCalendar .fc .fc-button {
  padding: 0 0.6em;
  background-color: #5a5278;
  border-color: #5a5278;
  color: #fff;
}

.customFullCalendar .fc-event-time {
  display: none;
}

.customFullCalendar .fc-button-active,
.customFullCalendar .fc-state-disabled,
.customFullCalendar .fc-button:hover {
  background-color: #7372b8 !important;
  border-color: #7372b8 !important;
}

.calendarTitleBox h3 {
  font-weight: 500;
}
.calendarUserName {
  font-size: 18px;
}

.calendarUserRole {
  font-size: 15px;
}

.calendarHeaderBox {
  display: flex;
  gap: 4px;
}

.calendarTitleBox {
  display: flex;
  flex-direction: column;
  gap: 18px;
}
.cardhover {
  cursor: pointer;
  position: "absolute";
  background: #fff;
}
.cardhover :hover {
  position: absolute;
  transform: translateY(-5px);
  /* width: 310px !important; */
  z-index: 2;
  /* background: #ffffff; */
  transition: all 0.5s ease-in-out;
}

.cardhover div {
  border-left: 5px solid #02005b;
  border-right: 1px solid #808080 !important;
  border-top: 1px solid #808080 !important;
  border-bottom: 1px solid #808080 !important;
}
.cardhover div:hover {
  background: #f1f1f1;
  box-shadow: 3px 3px 8px 0px #5858586e;
}
.cardhoverText {
  cursor: pointer;
}
.cardhoverText:hover {
  position: relative;
  transform: none;
  width: auto;
  z-index: 1;
  background: transparent;
  transition: none;
}
